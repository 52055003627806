import React, { useState, useEffect } from "react";
import MuxPlayer from "@mux/mux-player-react";
import { Table, Spinner, Card, CardImg, Button } from "reactstrap";
import "./Playlist.css";
import "../icons.css";
import {
  addToPlaylist,
  removeFromPlaylist,
  changeVideoOrder,
  getSinglePlaylist,
  getPlaylistVideos,
} from "../services/playlist.service";
const ManagePlaylist = (props) => {
  const [otherVideos, setVideos] = useState([]);
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [playlist_id, setPlaylistID] = useState(null);
  const [playlist, setPlaylist] = useState({});
  const [videoInfo, setVideoInfo] = useState({ playbackId: "", title: "" });
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const addToPlaylistClick = (v_id, e) => {
    addToPlaylist(v_id, playlist_id).then(() => {
      const record = [];
      setVideos(
        otherVideos.filter((ele) => {
          if (ele.video_id === v_id) {
            record.push(ele);
          }
          return ele.video_id !== v_id;
        })
      );
      setPlaylistVideos([record[0], ...playlistVideos]);
    });
  };
  const removeFromPlaylistClick = (v_id, e) => {
    removeFromPlaylist(v_id, playlist_id).then(() => {
      const record = [];
      setPlaylistVideos(
        playlistVideos.filter((ele) => {
          if (ele.video_id === v_id) {
            record.push(ele);
          }
          return ele.video_id !== v_id;
        })
      );
      setVideos([record[0], ...otherVideos]);
    });
  };
  const changeVideoOrderDown = (v_id, video_order, e) => {
    if (!video_order) {
      video_order = 0;
    }
    if (video_order === -1) {
      video_order = 0;
    }
    if (video_order > playlistVideos.length) {
      video_order = playlistVideos.length - 2;
    }
    changeVideoOrder(v_id, playlist_id, video_order);
    window.location.reload();
  };

  const changeVideoOrderUp = (v_id, video_order, e) => {
    if (!video_order) {
      video_order = playlistVideos.length - 1;
    }
    changeVideoOrder(v_id, playlist_id, video_order);
    window.location.reload();
  };

  const changePlayback = (url, title, e) => {
    if (!isPlayerOpen) {
      setIsPlayerOpen(true);
    }
    let firInd = url.lastIndexOf("/");
    let ltInd = url.lastIndexOf(".");
    const urlPlaybackId = url.substring(firInd + 1, ltInd);
    // console.log(url)
    console.log(urlPlaybackId);
    // videoInfo.playbackId = urlPlaybackId;
    // videoInfo.title=title;
    setVideoInfo({ playbackId: urlPlaybackId, title: title });
  };

  let IsPlayer = ({ children }) => {
    return isPlayerOpen ? children : <></>;
  };

  const is_hls = (url) => {
    return Boolean(url.includes(".m3u8"));
  };

  useEffect(() => {
    const id = window.location.href.split("/");

    getSinglePlaylist(parseInt(id[id.length - 1])).then((res) => {
      setPlaylist(res.data[0]);
      setPlaylistID(res.data[0].id);
    });
    getPlaylistVideos(parseInt(id[id.length - 1]))
      .then((res) => {
        setPlaylistVideos(
          res.data.filter((ele) => {
            return ele.inplaylist !== null;
          })
        );
        setVideos(
          res.data.filter((ele) => {
            return ele.inplaylist === null;
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <IsPlayer>
        <div
          style={{
            width: "1076px",
            position: "sticky",
            top: "9rem",
            marginLeft: "auto",
            right: "0",
          }}
        >
          <div
            style={{
              width: "533px",
              position: "fixed",
              marginLeft: "auto",
              right: "0",
              top: "9rem",
            }}
          >
            <h5
              style={{
                position: "fixed",
                marginLeft: "2rem",
                marginRight: "auto",
                color: "white",
              }}
            >
              {videoInfo.title}
            </h5>
            <Button
              style={{
                position: "fixed",
                marginLeft: "auto",
                right: "2rem",
                background: "transparent",
                color: "white",
                fontSize: "22px",
              }}
              onClick={() => {
                setIsPlayerOpen(false);
              }}
            >
              x
            </Button>

            <MuxPlayer
              style={{
                zIndex: "-1",
                position: "relative",
                display: "block",
                height: "300px",
                width: "533px",
                marginLeft: "auto",
                right: "0",
              }}
              streamType="on-demand"
              playbackId={videoInfo.playbackId}
              metadata={{
                video_id: "video-id-54321",
                video_title: `${videoInfo.title}`,
                viewer_user_id: "user-id-007",
              }}
            />
          </div>
        </div>
      </IsPlayer>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          Manage Playlist
        </p>
      </div>
      <div>
        {playlist_id ? (
          <div className="playlist-container">
            <Card className="playlist-card">
              <CardImg alt="Card image cap" src={playlist.image_url} top className="playlist-img" />
              <p className="playlist-heading">{playlist.name}</p>
            </Card>
            <div>
              <div className="table-container">
                {playlistVideos.length > 0 ? (
                  <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "200px" }}>Title</th>
                        <th style={{ width: "200px" }}>Artist</th>
                        <th style={{ width: "70px" }}>Order</th>
                        <th style={{ width: "70px" }}>Video Link</th>
                        <th style={{ width: "70px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {playlistVideos.map((record) => {
                        return (
                          <tr key={record.video_id}>
                            <td>{record.title}</td>
                            <td>{record.artist}</td>
                            <td>
                              <div>
                                <button
                                  onClick={changeVideoOrderDown.bind(
                                    this,
                                    record.video_id,
                                    record.order - 1
                                  )}
                                >
                                  -
                                </button>{" "}
                                ----{record.order}----
                                <button
                                  onClick={changeVideoOrderUp.bind(
                                    this,
                                    record.video_id,
                                    record.order + 1
                                  )}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              {is_hls(record.video_url) ? (
                                <Button
                                  className="view-icon btn"
                                  title="view video"
                                  onClick={changePlayback.bind(
                                    this,
                                    record.video_url,
                                    record.title
                                  )}
                                />
                              ) : (
                                <a
                                  href={record.video_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="view-icon btn" title="view video"></div>
                                </a>
                              )}
                            </td>
                            <td>
                              <div
                                className="cross-icon btn"
                                onClick={removeFromPlaylistClick.bind(this, record.video_id)}
                              ></div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className="no_playlist_msg">
                    <h4>No Video in this Playlist</h4>
                  </div>
                )}
              </div>
              <div className="table-container">
                <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>Title</th>
                      <th style={{ width: "200px" }}>Artist</th>
                      <th style={{ width: "70px" }}>Video Link</th>
                      <th style={{ width: "70px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherVideos
                      .sort((a, b) =>
                        b.video_id < a.video_id ? -1 : b.video_id > a.video_id ? 1 : 0
                      )
                      .map((record) => {
                        return (
                          <tr key={record.video_id}>
                            <td>{record.title}</td>
                            <td>{record.artist}</td>
                            <td>
                              {is_hls(record.video_url) ? (
                                <Button
                                  className="view-icon btn"
                                  title="view video"
                                  onClick={changePlayback.bind(
                                    this,
                                    record.video_url,
                                    record.title
                                  )}
                                />
                              ) : (
                                <a
                                  href={record.video_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div className="view-icon btn" title="view video"></div>
                                </a>
                              )}
                            </td>
                            <td>
                              <div
                                className="plus-icon btn"
                                onClick={addToPlaylistClick.bind(this, record.video_id)}
                              ></div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
    </div>
  );
};

export default ManagePlaylist;
