import React, { useState, useEffect } from "react";
import { Table, Spinner } from "reactstrap";
import "./FeaturedVideo.css";
import "../icons.css";
import {
  getFeaturedVideos,
  getAllVideos,
  addVideo,
  removeVideo,
} from "../services/featured.service";
const moment = require("moment");

const FeaturedVideo = (props) => {
  const [otherVideos, setVideos] = useState([]);
  const [featuredVideos, setFeaturedVideos] = useState([]);

  const toHHMMSS = (duration) => {
    let hh = parseInt(duration / 3600);
    duration = duration % 3600;
    let mm = parseInt(duration / 60);
    duration = duration % 60;
    let ss = parseInt(duration);
    if (hh < 10) {
      hh = `0${hh}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (ss < 10) {
      ss = `0${ss}`;
    }
    return `${hh}:${mm}:${ss}`;
  };

  const addVideoClick = (v_id, e) => {
    const todayDate = new Date(new Date().toUTCString());
    let newST = "";

    if (featuredVideos.length < 1) {
      newST = `${todayDate.toISOString().split("T")[0]} ${
        todayDate.toISOString().split("T")[1].split(".")[0]
      }`;
    } else {
      let lastVideo = featuredVideos[featuredVideos.length - 1];
      let newTime = moment(lastVideo.streaming_time)
        .add(lastVideo.duration + 1, "seconds")
        .format();
      newST = newTime.split("T")[0] + " " + newTime.split("T")[1].split("-")[0];
    }

    addVideo(v_id, newST).then(() => {
      const record = [];
      otherVideos.forEach((ele) => {
        if (ele.id === v_id) {
          record.push(ele);
        }
      });

      const video = {
        video_id: record[0].id,
        title: record[0].title,
        artist: record[0].artist,
        duration: record[0].duration,
        streaming_time: newST,
      };
      setFeaturedVideos([...featuredVideos, video]);
    });
  };

  const removeVideoClick = (v_id, streaming_time, e) => {
    removeVideo(v_id, streaming_time).then(() => {
      const record = [];
      setFeaturedVideos(
        featuredVideos.filter((ele) => {
          if (ele.video_id === v_id) {
            record.push(ele);
          }
          return ele.video_id !== v_id;
        })
      );
    });
  };

  useEffect(() => {
    getFeaturedVideos()
      .then((res) => {
        setFeaturedVideos(res.data);
      })
      .catch((err) => console.log(err));

    getAllVideos()
      .then((res) => {
        setVideos(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const isStreamingTmLater = (streaming_time) => {
    const today = new Date(new Date().toUTCString());
    const todayDate = new Date(
      new Date(
        today.toISOString().split("T")[0] + " " + today.toISOString().split("T")[1].split(".")[0]
      ).toUTCString()
    );

    return new Date(new Date(streaming_time).toUTCString()) > todayDate;
  };

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          Featured Videos
        </p>
      </div>
      <div>
        {featuredVideos ? (
          <div>
            <div className="table-container">
              {featuredVideos.length > 0 ? (
                <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "200px" }}>Title</th>
                      <th style={{ width: "200px" }}>Artist</th>
                      <th style={{ width: "70px" }}>Duration</th>
                      <th style={{ width: "150px" }}>Streaming time</th>
                      <th style={{ width: "70px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {featuredVideos.map((record) => {
                      return (
                        <tr key={record.id}>
                          <td>{record.title}</td>
                          <td>{record.artist}</td>
                          <td>{toHHMMSS(record.duration)}</td>
                          <td>{record.streaming_time}</td>
                          <td>
                            {isStreamingTmLater(record.streaming_time) ? (
                              <div
                                className="cross-icon btn"
                                onClick={removeVideoClick.bind(
                                  this,
                                  record.video_id,
                                  record.streaming_time
                                )}
                              ></div>
                            ) : (
                              <div></div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_playlist_msg">
                  <h4>No Videos in Featured Table for today </h4>
                </div>
              )}
            </div>
            <div className="table-container">
              <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "200px" }}>Title</th>
                    <th style={{ width: "200px" }}>Artist</th>
                    <th style={{ width: "70px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {otherVideos.map((record) => {
                    return (
                      <tr key={record.id}>
                        <td>{record.title}</td>
                        <td>{record.artist}</td>
                        <td>
                          <div
                            className="plus-icon btn"
                            onClick={addVideoClick.bind(this, record.id)}
                          ></div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
    </div>
  );
};

export default FeaturedVideo;
