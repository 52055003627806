import React, { useState } from "react";
import "./Thumbnail.css";
import thumbnail_preview from "../images/thumbnail_preview.jpg";
import axios from "axios";
import authHeader from "../services/auth-header";
import config from "../config";

import {
  InputGroupText,
  InputGroup,
  Label,
  FormGroup,
  Input,
  Button,
  Alert,
  Progress,
} from "reactstrap";
import { saveMusicVideo } from "../services/video.service";

const INITIAL_VALUES = {
  videoFile: { video: null, duration: null },
  uploading: false,
  progressVal: 0,
  image: { image: null, preview: null },
  albumArt: { image: null, preview: null },
  state: {
    artist: "",
    title: "",
    typeOfVideo: "file",
    link: "",
    linkOut: ""
  },
}

const UploadForm = () => {
  const [videoFile, setVideoFile] = useState(INITIAL_VALUES.videoFile);
  const [uploading, setUploading] = useState(INITIAL_VALUES.uploading);
  const [progressVal, setProgressVal] = useState(INITIAL_VALUES.progressVal);
  const [image, setImage] = useState(INITIAL_VALUES.image);
  const [albumArt, setAlbumArt] = useState(INITIAL_VALUES.albumArt);
  const [state, setState] = useState(INITIAL_VALUES.state);
  const [error, setError] = useState(null);
  //const [videoLink, setVideoLink] = useState({duration: null});

  const toHHMMSS = (duration) => {
    let hh = parseInt(duration / 3600);
    duration = duration % 3600;
    let mm = parseInt(duration / 60);
    duration = duration % 60;
    let ss = parseInt(duration);
    if (hh < 10) {
      hh = `0${hh}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (ss < 10) {
      ss = `0${ss}`;
    }
    return `${hh}:${mm}:${ss}`;
  };
  const handleState = (e) => {
    if (e.target.id === "title") {
      setState({
        artist: state.artist,
        title: e.target.value,
        typeOfVideo: state.typeOfVideo,
        link: state.link,
        linkOut: state.linkOut
      });
    } else if (e.target.id === "artist") {
      setState({
        artist: e.target.value,
        title: state.title,
        typeOfVideo: state.typeOfVideo,
        link: state.link,
        linkOut: state.linkOut
      });
    } else if (e.target.id === "linkOut") {
      setState({
        artist: state.artist,
        title: state.title,
        typeOfVideo: state.typeOfVideo,
        link: state.link,
        linkOut: e.target.value
      });
    } else if (e.target.id === "vlink") {
      state.link = e.target.value;
      //setState({ artist: state.artist, title: state.title, typeOfVideo: state.typeOfVideo, link: e.target.value });
      // getVideoDurationInSeconds( e.target.value
      // ).then((duration) => {
      //   setVideoLink({duration: duration})
      // });
    } else if (e.target.name === "radio1") {
      setState({
        artist: state.artist,
        title: state.title,
        typeOfVideo: e.target.value,
        link: state.link,
        linkOut: state.linkOut
      });
    }
  };

  let IsFileInput = ({ children }) => {
    return state.typeOfVideo === "file" ? children : <></>;
  };

  let IsLinkInput = ({ children }) => {
    return state.typeOfVideo === "link" ? children : <></>;
  };

  const handleImageChange = (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImage({ preview: reader.result, image: selected });
      };
      reader.readAsDataURL(selected);
    } else {
      setError("Image Formet not Suppoerted");
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const handleAlbumArtChange = (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setAlbumArt({ preview: reader.result, image: selected });
      };
      reader.readAsDataURL(selected);
    } else {
      setError("Image Formet not Suppoerted");
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const handleVideoChange = (e) => {
    const video = e.target.files[0];
    const videoURL = URL.createObjectURL(video);
    const audio = new Audio();
    audio.src = videoURL;
    const ALLOWED_TYPES = ["video/mp4"];
    if (video && ALLOWED_TYPES.includes(video.type)) {
      audio.onloadedmetadata = () => {
        setVideoFile({ video: video, duration: audio.duration });
      }
    } else {
      setError("Video Format not Suppoerted");
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const saveRecord = () => {
    if (state.typeOfVideo === "file") {
      setUploading(true);
      setProgressVal(0);
      if (
        (videoFile.video != null && image.image != null,
        state.title != null && state.artist != null)
      ) {
        let formdata = new FormData();
        formdata.append("thumbnail", image.image);
        formdata.append("album_artwork", albumArt.image);
        formdata.append("video", videoFile.video);
        formdata.append("title", state.title);
        formdata.append("artist", state.artist);
        formdata.append("link_out", state.linkOut);
        formdata.append("duration", videoFile.duration);
        formdata.append("typeOfVideo", state.typeOfVideo);

        axios.post(config.BASE_URL + "/video", formdata, {
          headers: authHeader(),
          onUploadProgress: (progress) => {
            if (progress.lengthComputable) {
              setProgressVal(Math.round((progress.loaded / progress.total) * 100));
            }
          }
  })
          .then((res) => {
            console.log(res);
            setUploading(false);
            setError("Record Saved");
            setVideoFile(INITIAL_VALUES.videoFile);
            setAlbumArt(INITIAL_VALUES.albumArt);
            setImage(INITIAL_VALUES.image);
            setProgressVal(INITIAL_VALUES.progressVal);
            setState(INITIAL_VALUES.state);
            setTimeout(() => {
              setError(null);
            }, 5000);
          })
          .catch((err) => {
            // console.log(err);
            setUploading(false);
            setError("Something Bad Occurred....Please Try Again!!");
            setTimeout(() => {
              setError(null);
            }, 5000);
          });
      } else {
        setError("All fields Required");
        setUploading(false);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } else if (state.typeOfVideo === "link") {
      setUploading(true);
      if (
        (state.link != null && image.image != null, state.title != null && state.artist != null)
      ) {
        let formdata = new FormData();
        formdata.append("thumbnail", image.image);
        formdata.append("album_artwork", albumArt.image);
        formdata.append("link", state.link);
        formdata.append("title", state.title);
        formdata.append("artist", state.artist);
        formdata.append("link_out", state.linkOut);
        //formdata.append("duration", videoLink.duration);
        formdata.append("typeOfVideo", state.typeOfVideo);

        saveMusicVideo(formdata)
          .then((res) => {
            // console.log(res);
            setUploading(false);
            setProgressVal(100);
            setError("Record Saved");
            setTimeout(() => {
              setError(null);
            }, 5000);
          })
          .catch((err) => {
            // console.log(err);
            setUploading(false);
            setError("Something Bad Occurred....Please Try Again!!");
            setTimeout(() => {
              setError(null);
            }, 5000);
          });
      } else {
        setError("All fields Required");
        setUploading(false);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };

  return (
    <div className="custom-container">
      <div>
        <p
          style={{
            color: "#d76ceb",
            fontSize: "25px",
            fontWeight: "500",
          }}
        >
          Add Music Video
        </p>
      </div>
      {error ? <Alert color="danger" fade={false}>{error}</Alert> : <div></div>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <InputGroup>
            <InputGroupText style={{ color: "white" }}>Title </InputGroupText>
            <Input onChange={handleState} value={state.title} id="title" />
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroupText style={{ color: "white" }}>Artist</InputGroupText>
            <Input onChange={handleState} value={state.artist} id="artist" />
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroupText style={{ color: "white" }}>Link Out</InputGroupText>
            <Input onChange={handleState} value={state.linkOut} id="linkOut" />
          </InputGroup>
          <br />
          <FormGroup>
            <FormGroup check>
              <Label check style={{ color: "white" }}>
                <Input
                  type="radio"
                  id="isFile"
                  name="radio1"
                  value="file"
                  defaultChecked
                  onChange={handleState}
                />{" "}
                Upload a file
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check style={{ color: "white" }}>
                <Input type="radio" id="isLink" name="radio1" value="link" onChange={handleState} />{" "}
                Enter a link
              </Label>
            </FormGroup>
          </FormGroup>
          <div>
            <IsFileInput>
              <p
                style={{
                  color: "white",
                  fontSize: "15px",
                  marginBottom: "0.3rem",
                }}
              >
                Upload Music Video
              </p>
              <Input
                type="file"
                accept="video/*"
                placeholder="upload music video"
                style={{ marginBottom: "0.5rem" }}
                onChange={handleVideoChange}
              />
              {videoFile.video ? (
                <div style={{ color: "white", height: "2rem" }}>
                  Length: {toHHMMSS(videoFile.duration)}
                </div>
              ) : (
                <div style={{ height: "2rem" }}></div>
              )}
            </IsFileInput>

            <IsLinkInput>
              <InputGroup>
                <InputGroupText
                  style={{
                    color: "white",
                    fontSize: "15px",
                    marginBottom: "0.3rem",
                  }}
                >
                  Enter a link
                </InputGroupText>
                <Input onChange={handleState} id="vlink" value={state.link} placeholder="www.fo.com" />
              </InputGroup>
            </IsLinkInput>
          </div>
        </div>
        <div>
          <div className="form-element">
            <input type="file" id="album_artwork" accept="image/*" onChange={handleAlbumArtChange} />
            <label htmlFor="album_artwork" id="album_artwork-preview">
              {albumArt.preview ? (
                <img src={albumArt.preview} alt="" />
              ) : (
                <img src={thumbnail_preview} alt="" />
              )}
              <div>{!albumArt.preview ? <span>Upload Album Artwork</span> : <span>Change</span>}</div>
            </label>
          </div>
          <br/>
          <div className="form-element">
            <input type="file" id="thumbnail" accept="image/*" onChange={handleImageChange} />
            <label htmlFor="thumbnail" id="thumbnail-preview">
              {image.preview ? (
                <img src={image.preview} alt="" />
              ) : (
                <img src={thumbnail_preview} alt="" />
              )}
              <div>{!image.preview ? <span>Upload Thumbnail</span> : <span>Change</span>}</div>
            </label>
          </div>
        </div>
      </div>
      {uploading ? (
        <div
          style={{
            margin: "1rem auto",
          }}
        >
          <Progress animated value={progressVal} />
          <p style={{ color: "white", float: "right" }}>Saving Video</p>
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ margin: "1rem 0 0" }}>
        <Button
          style={{
            width: "15rem",
            background: "linear-gradient(to right, #5555ff , #eb6eec, #fed53b)",
            color: "black",
            fontWeight: "500",
          }}
          onClick={saveRecord}
        >
          Save Video
        </Button>
      </div>
    </div>
  );
};

export default UploadForm;
