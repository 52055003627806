import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getSingleCategory = (id) => {
  return axios.get(config.BASE_URL + "/category/single/" + id, {
    headers: authHeader(),
  });
};

export const getCategories = () => {
  return axios.get(config.BASE_URL + "/category", { headers: authHeader() });
};

export const getCategoryPlaylists = (category_id) => {
  return axios.get(config.BASE_URL + `/category/${category_id}`, {
    headers: authHeader(),
  });
};

export const changeCategoryOrder = (id, order, pre_order) => {
  return axios.post(
    config.BASE_URL + `/category/${id}/${order}/${pre_order}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const addToCategory = (category_id, playlist_id) => {
  return axios.post(
    config.BASE_URL + `/category/${category_id}/${playlist_id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const removeFromCategory = (category_id, playlist_id) => {
  return axios.delete(config.BASE_URL + `/category/${category_id}/${playlist_id}`, {
    headers: authHeader(),
  });
};
export const changeOrderToNumOne = (data) => {
  return axios.post(config.BASE_URL + "/category", data, { headers: authHeader() });
};
