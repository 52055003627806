import React, { useState, useEffect } from "react";
import { Table, Alert, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import "./Thumbnail.css";
import "../icons.css";
import {
  getCategories,
  changeCategoryOrder,
  changeOrderToNumOne,
} from "../services/category.service";

const Categories = () => {
  const [showAlert, setAlert] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState(-1);
  const [numOneCat, setNumOneCat] = useState("");

  const changeOrderDown = (id, order, e) => {
    if (!order) {
      order = 0;
    }
    if (order === -1) {
      order = 0;
    }
    changeCategoryOrder(id, order, 0);
    window.location.reload();
  };

  const changeOrderUp = (id, order, e) => {
    if (!order) {
      order = categories.length - 1;
    }
    changeCategoryOrder(id, order, 0);
    window.location.reload();
  };

  const handleChange = (e) => {
    setSelectedCatId(e.target.value);
  };

  const sendCategories = () => {
    let newOrder = [];
    categories.forEach((cat) => {
      let catOrd = { id: cat.id, order: cat.order, name: cat.name };
      newOrder.push(catOrd);
    });

    newOrder.forEach((ord) => {
      ord.order++;
    });

    newOrder.forEach((ord) => {
      if (ord.id == selectedCatId) {
        ord.order = 1;
      }
    });
    if (selectedCatId !== -1) {
      changeOrderToNumOne(newOrder);
      window.location.reload();
    }
  };

  useEffect(() => {
    getCategories()
      .then((res) => {
        console.log(res);
        setCategories(res.data.categories);
        setNumOneCat(res.data.numOne);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ margin: "2rem" }}>
      {showAlert ? (
        <Alert
          color="dark"
          style={{
            position: "absolute",
            right: "2rem",
            padding: "5px 2rem",
          }}
        >
          Copied
        </Alert>
      ) : (
        <div></div>
      )}
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
          }}
        >
          All Categories
        </p>
      </div>
      <div style={{ width: "100%" }}>
        {categories.length > 0 ? (
          <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>Title</th>
                <th style={{ width: "200px" }}>Order</th>
                <th style={{ width: "75px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((record, idx) => {
                const path = "/category/" + record.name + "/" + record.id;
                return (
                  <tr key={record.id}>
                    <td>{record.name}</td>
                    <td>
                      <div>
                        <button onClick={changeOrderDown.bind(this, record.id, record.order - 1)}>
                          -
                        </button>{" "}
                        ----{record.order}----
                        <button onClick={changeOrderUp.bind(this, record.id, record.order + 1)}>
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <Link to={path}>
                        <Button outline style={{ border: "solid 1px #eb6eec" }}>
                          Manage
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
        <div style={{ textAlign: "center" }}>
          <select onChange={handleChange.bind()}>
            <option disabled selected value>
              {" "}
              -- select a category --{" "}
            </option>
            {categories.map((record) => {
              return <option value={record.id}> {record.name} </option>;
            })}
          </select>
          <Button onClick={sendCategories.bind()}>Set category's order to #1</Button>
          <br />

          <p
            style={{
              marginTop: "2rem",
              fontSize: "18px",
            }}
          >
            {numOneCat} is scheduled to change its order to #1 tomorrow at 7:01am UTC
          </p>
        </div>
      </div>
    </div>
  );
};

export default Categories;
