import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getXmlFiles = () => {
  return axios.get(config.BASE_URL + "/ddex", { headers: authHeader() });
};

export const performDdex = (data) => {
  return axios.post(config.BASE_URL + "/ddex/run", data, { headers: authHeader() });
};
export const performSymphonic = (data) => {
  return axios.post(config.BASE_URL + "/ddex/symphonic", data, { headers: authHeader() });
};

export const performDpm = (data) => {
  return axios.post(config.BASE_URL + "/ddex/dpm", data, { headers: authHeader() });
};

export const updateXml = (data) => {
  return axios.post(config.BASE_URL + "/ddex/update", data, { headers: authHeader() });
};