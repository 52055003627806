import React, { useState, useEffect } from "react";
import MuxPlayer from "@mux/mux-player-react";
import { Table, Alert, Modal, ModalBody, Spinner, Button, Input } from "reactstrap";
import { saveAs } from "file-saver";
import "./Thumbnail.css";
import "../icons.css";
import "./Video.css";
import thumbnail_preview from "../images/thumbnail_preview.jpg";
import { getCurrentUser } from "../services/auth.service";
import { deleteVideo, getVideos, changeVideosUrls, changeVideoActive, changeVideoHide, saveMp4 } from "../services/video.service";
import config from "../config";

const Videos = () => {
  const [showAlert] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, id: null, title: null, hide: null });
  const [videos, setVideos] = useState([]);
  const [videoInfo, setVideoInfo] = useState({ playbackId: "", title: "" });
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnail_preview);
  
  const toHHMMSS = (duration) => {
    let hh = parseInt(duration / 3600);
    duration = duration % 3600;
    let mm = parseInt(duration / 60);
    duration = duration % 60;
    let ss = parseInt(duration);
    if (hh < 10) {
      hh = `0${hh}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (ss < 10) {
      ss = `0${ss}`;
    }
    return `${hh}:${mm}:${ss}`;
  };

  const toggleModal = (v_id, v_title, v_hide, e) => {
    setModal({ isOpen: !modal.isOpen, id: v_id, title: v_title, hide: v_hide });
  };

  const IsAdmin = ({ children }) => {
    const user = getCurrentUser();
    return user.user.name === config.ADMIN || user.user.name === config.SEC_ADMIN? children : <></>;
  };

  const changeUrl = (v_id, v_url, e) => {
    const data = { id: v_id, url: v_url };
    let record = [];
    changeVideosUrls(data).then((res) => {
      setVideos(
        videos.filter((ele) => {
          if (ele.id === v_id) {
            record.push(ele);
          }
          return ele.id !== v_id;
        })
      );

      record[0].video_url = res.data.newUrl;
      record[0].encodeErr = res.data.err;
      setVideos([...videos, record[0]]);
    });
  };

  const allowResave = (v_id, v_url, e) => {
    const playbackId = v_url.split('/')[3].split('.')[0];
    const data = { id: v_id, playbackId: playbackId };
    let record = [];
    saveMp4(data).then((res) => {
      setVideos(
        videos.filter((ele) => {
          if (ele.id === v_id) {
            record.push(ele);
          }
          return ele.id !== v_id;
        })
      );

      record[0].mp4_link = res.data.mp4_link;
      setVideos([...videos, record[0]]);
    });
  };

  const changePlayback = (url, title, thumbnail_url, e) => {
    if (!isPlayerOpen) {
      setIsPlayerOpen(true);
    }
    let firInd = url.lastIndexOf("/");
    let ltInd = url.lastIndexOf(".");
    const urlPlaybackId = url.substring(firInd + 1, ltInd);
    // console.log(url)
    console.log(urlPlaybackId);
    // videoInfo.playbackId = urlPlaybackId;
    // videoInfo.title=title;
    setVideoInfo({ playbackId: urlPlaybackId, title: title });
    setThumbnailUrl(thumbnail_url);
  };

  const deleteRecord = (id, e) => {
    e.stopPropagation();
    deleteVideo(id).then((res) => {
      setVideos(
        videos.filter((ele) => {
          return ele.id !== id;
        })
      );
      toggleModal();
    });
  };
  useEffect(() => {
    let records = [];
    getVideos()
      .then((res) => {
        records = res.data;
        records.forEach(video => {
          video.encodeErr = "";
        });
        setVideos(records);
      })
      .catch((err) => console.log(err));
  }, []);

  let IsPlayer = ({ children }) => {
    return isPlayerOpen ? children : <></>;
  };

  const is_hls = (url) => {
    return Boolean(url.includes(".m3u8"));
  };

  const is_active = (record) => {
    return record.is_active
  }

  const changeActive = (v_id, v_active, e) => {
    changeVideoActive({ id: v_id, is_active: v_active });
    setTimeout(() => {
    window.location.reload();
    }, 2000);
  };

  const changeHide = (v_id, v_hide, e) => {
    changeVideoHide({ id: v_id, hide: v_hide });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };


  return (
    <div style={{ margin: "2rem" }}>
      {showAlert ? (
        <Alert
          color="dark"
          style={{
            position: "absolute",
            right: "2rem",
            padding: "5px 2rem",
          }}
        >
          Copied
        </Alert>
      ) : (
        <div></div>
      )}

      <IsPlayer>
        <div
          style={{
            width: "1076px",
            position: "sticky",
            top: "9rem",
            marginLeft: "auto",
            right: "0",
          }}
        >
          <div
            style={{
              width: "533px",
              position: "fixed",
              marginLeft: "0",
              marginRight: "auto",
              top: "9rem",
            }}
          >
            <img
              style={{
                width: "auto",
                height: "300px",
                position: "absolute",
                right: "0",
                marginLeft: "auto",
              }}
              src={thumbnailUrl}
              alt=""
            />
          </div>

          <div
            style={{
              width: "533px",
              position: "fixed",
              marginLeft: "auto",
              right: "0",
              top: "9rem",
            }}
          >
            <h5
              style={{
                position: "fixed",
                marginLeft: "2rem",
                marginRight: "auto",
                color: "white",
              }}
            >
              {videoInfo.title}
            </h5>
            <Button
              style={{
                position: "fixed",
                marginLeft: "auto",
                right: "2rem",
                background: "transparent",
                color: "white",
                fontSize: "22px",
              }}
              onClick={() => {
                setIsPlayerOpen(false);
              }}
            >
              x
            </Button>

            <MuxPlayer
              style={{
                zIndex: "-1",
                position: "relative",
                display: "block",
                height: "300px",
                width: "533px",
                marginLeft: "auto",
                right: "0",
              }}
              streamType="on-demand"
              playbackId={videoInfo.playbackId}
              metadata={{
                video_id: "video-id-54321",
                video_title: `${videoInfo.title}`,
                viewer_user_id: "user-id-007",
              }}
            />
          </div>
        </div>
      </IsPlayer>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
          }}
        >
          All Videos
        </p>
      </div>
      <div style={{ width: "100%" }}>
        {videos.length > 0 ? (
          <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "70px" }}>ID</th>
                <th style={{ width: "200px" }}>Title</th>
                <th style={{ width: "250px" }}>Artist</th>
                <th style={{ width: "100px" }}>Video</th>
                <th style={{ width: "100px" }}>Album artwork</th>
                <th style={{ width: "100px" }}>Duration</th>
                <IsAdmin>
                <th style={{ width: "100px" }}>Record label</th>
                <th style={{ width: "100px" }}>Genre</th>
                <th style={{ width: "100px" }}>Release Date</th>
                </IsAdmin>
                <th style={{ width: "50px" }}>Active</th>
                <IsAdmin>
                  <th style={{ width: "90px" }}>Hide</th>
                </IsAdmin>
                <th style={{ width: "90px" }}>Action</th>
                <th style={{ width: "110px" }}>Download original</th>
              </tr>
            </thead>

            <tbody>
              {videos.map((record, idx) => {
                return (
                  <tr key={record.id}>
                    <th scope="row">{record.id}</th>
                    <td>{record.title}</td>
                    <td>{record.artist}</td>
                    <td>
                      {is_hls(record.video_url) ? (
                        <Button
                          className="view-icon btn"
                          title="view video"
                          onClick={changePlayback.bind(
                            this,
                            record.video_url,
                            record.title,
                            record.thumbnail_url
                          )}
                        />
                      ) : (
                        <a href={record.video_url} target="_blank" rel="noopener noreferrer">
                          <div className="view-icon btn" title="view video"></div>
                        </a>
                      )}
                      <div
                        className="copy-icon btn"
                        onClick={() => {
                          navigator.clipboard.writeText(record.video_url);
                        }}
                        title="copy link"
                      ></div>
                    </td>
                    <td>
                      <a href={record.album_artwork} target="_blank" rel="noopener noreferrer">
                        <div className="view-icon btn" title="view album artwork"></div>
                      </a>
                    </td>
                    <td>{toHHMMSS(record.duration)}</td>
                    <IsAdmin>
                      <td>{record.record_label}</td>
                      <td>{record.genre}</td>
                    <td>{new Date(record.originalreleasedate).toISOString().split("T")[0]}</td>
                    </IsAdmin>
                    <td>
                      <Input
                        type="checkbox"
                        checked={is_active(record)}
                        onChange={changeActive.bind(this, record.id, record.is_active)}
                      />
                    </td>
                    <IsAdmin>
                      <td>
                        <Button
                          onClick={toggleModal.bind(this, record.id, record.title, record.hide)}
                        >
                          Move
                        </Button>
                      </td>
                    </IsAdmin>
                    <td>
                      {is_hls(record.video_url) ? (
                        <a href={`https://vibe-stream.tv/video/${record.video_id}`} target="_blank" rel="noopener noreferrer">
                          <Button>Open</Button>
                        </a>
                      ) : (
                        <Button onClick={changeUrl.bind(this, record.id, record.video_url)}>
                          Encode
                        </Button>
                      )}
                      {(record.encodeErr.length > 0) ? (
                        <p>{record.encodeErr}</p>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {record.original_file ? (
                        <Button onClick={() => {
                          const arr = record.original_file.split(".");
                          saveAs(record.original_file,
                            `${record.title.replaceAll(" ", "_")}.${arr[arr.length - 1]}`);
                          }}>Download</Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>

      <Modal
        centered
        toggle={function noRefCheck() {}}
        isOpen={modal.isOpen}
        style={{ textAlign: "center" }}
        fade={false}
      >
        <ModalBody style={{ padding: "4rem" }}>
          Are You Sure, you want to move video titled "<span>{modal.title}</span>" to hide?
          <br />
          <div
            className="btn btn-warning mb-3 mt-3"
            style={{ width: "5rem" }}
            onClick={changeHide.bind(this, modal.id, modal.hide)}
          >
            Yes
          </div>
          {"   "}
          <div
            className="btn btn-outline-warning mt-3 mb-3"
            style={{ width: "5rem", marginLeft: "1rem" }}
            onClick={toggleModal}
          >
            Cancel
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Videos;
