import React, { useState, useEffect } from "react";
import { Table, Alert, Spinner } from "reactstrap";
import "./Thumbnail.css";
import "../icons.css";
import { getCompositions } from "../services/billing.service";

const Compositions = () => {
  const [showAlert] = useState(false);
  const [compositions, setCompositions] = useState([]);

  useEffect(() => {
    getCompositions()
      .then((res) => {
        setCompositions(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ margin: "2rem" }}>
      {showAlert ? (
        <Alert
          color="dark"
          style={{
            position: "absolute",
            right: "2rem",
            padding: "5px 2rem",
          }}
        >
          Copied
        </Alert>
      ) : (
        <div></div>
      )}
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
          }}
        >
          All Compositions
        </p>
      </div>
      <div style={{ width: "100%" }}>
        {compositions.length > 0 ? (
          <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "70px" }}>Video ID</th>
                <th style={{ width: "200px" }}>Title</th>
                <th style={{ width: "250px" }}>Artist</th>
                <th style={{ width: "200px" }}>Publisher</th>
                <th style={{ width: "70px" }}>Percent Controlled</th>
              </tr>
            </thead>
            <tbody>
              {compositions.map((record, idx) => {
                return (
                  <tr key={record.composition_billing_id}>
                    <th scope="row">{record.video_id}</th>
                    <td>{record.title}</td>
                    <td>{record.artist}</td>
                    <td>{record.publisher_partner}</td>
                    <td>{record.percent_controlled}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
    </div>
  );
};

export default Compositions;
