import React, { useState, useEffect } from "react";
import { Table, Spinner, Card, CardImg } from "reactstrap";
import "./Playlist.css";
import "../icons.css";
import {
  getSingleCategory,
  getCategoryPlaylists,
  addToCategory,
  removeFromCategory,
} from "../services/category.service";

const ManageCategory = (props) => {
  const [otherPlaylists, setPlaylists] = useState([]);
  const [categoryPlaylists, setCategoryPlaylists] = useState([]);
  const [category_id, setCategoryID] = useState(null);
  const [category, setCategory] = useState({});

  const addToCat = (p_id, e) => {
    addToCategory(category_id, p_id).then(() => {
      const record = [];
      setPlaylists(
        otherPlaylists.filter((ele) => {
          if (ele.playlist_id === p_id) {
            record.push(ele);
          }
          return ele.playlist_id !== p_id;
        })
      );
      setCategoryPlaylists([record[0], ...categoryPlaylists]);
    });
  };
  const removeFromCat = (p_id, e) => {
    removeFromCategory(category_id, p_id).then(() => {
      const record = [];
      setCategoryPlaylists(
        categoryPlaylists.filter((ele) => {
          if (ele.playlist_id === p_id) {
            record.push(ele);
          }
          return ele.playlist_id !== p_id;
        })
      );
      setPlaylists([record[0], ...otherPlaylists]);
    });
  };

  useEffect(() => {
    const id = window.location.href.split("/");

    getSingleCategory(parseInt(id[id.length - 1])).then((res) => {
      setCategory(res.data[0]);
      setCategoryID(res.data[0].id);
    });
    getCategoryPlaylists(parseInt(id[id.length - 1]))
      .then((res) => {
        setCategoryPlaylists(
          res.data.filter((ele) => {
            return ele.in_category !== null;
          })
        );
        setPlaylists(
          res.data.filter((ele) => {
            return ele.in_category === null;
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{ margin: "1rem 2rem" }}>
      <div>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          Manage Category: {category.name}
        </p>
      </div>
      <div>
        {categoryPlaylists ? (
          <div>
            <div className="table-container">
              {categoryPlaylists.length > 0 ? (
                <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "70px" }}>ID</th>
                      <th style={{ width: "200px" }}>Name</th>
                      <th style={{ width: "70px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryPlaylists.map((record) => {
                      return (
                        <tr key={record.playlist_id}>
                          <td>{record.playlist_id}</td>
                          <td>{record.name}</td>
                          <td>
                            <div
                              className="cross-icon btn"
                              onClick={removeFromCat.bind(this, record.playlist_id)}
                            ></div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_playlist_msg">
                  <h4>No Playlists in Category {category.name} </h4>
                </div>
              )}
            </div>
            <div className="table-container">
              <Table responsive striped style={{ background: "white", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "70px" }}>ID</th>
                    <th style={{ width: "200px" }}>Name</th>
                    <th style={{ width: "70px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {otherPlaylists.map((record) => {
                    return (
                      <tr key={record.playlist_id}>
                        <td>{record.playlist_id}</td>
                        <td>{record.name}</td>
                        <td>
                          <div
                            className="plus-icon btn"
                            onClick={addToCat.bind(this, record.playlist_id)}
                          ></div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>
    </div>
  );
};

export default ManageCategory;
