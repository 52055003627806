import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getFeaturedVideos = () => {
  return axios.get(config.BASE_URL + `/featured`, {
    headers: authHeader(),
  });
};

export const getAllVideos = () => {
  return axios.get(config.BASE_URL + `/featured/all`, {
    headers: authHeader(),
  });
};

export const addVideo = (video_id, streaming_time) => {
  return axios.post(
    config.BASE_URL + `/featured/${video_id}/${streaming_time}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

export const removeVideo = (video_id, streaming_time) => {
  return axios.delete(config.BASE_URL + `/featured/${video_id}/${streaming_time}`, {
    headers: authHeader(),
  });
};
