import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

export const getAllVideosReport = () => {
  return axios.get(config.BASE_URL + "/reporting", { headers: authHeader() });
};

export const getReportByMonth = (month) => {
  return axios.get(config.BASE_URL + `/reporting/${month}`, 
    { headers: authHeader() });
};

export const getReportByDay = (month, day) => {
  return axios.get(config.BASE_URL + `/reporting/${month}/${day}`,
    { headers: authHeader() });
};